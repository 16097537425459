import React from 'react';
import Helmet from 'react-helmet';
import { StyleSheet, css } from 'aphrodite/no-important';
import Context from '../context/Context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Layout from '../components/Layout';
import Cta from '../components/Cta';
import { widths } from '../utils/constants';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      return <img src={file['en-US'].url} alt={title['en-US']} />;
    },
  },
};

const renderTable = (tableData) => {
  const [header] = tableData.slice(0, 1);
  const body = tableData.slice(1);

  return (
    <table className={css(styles.table)}>
      <thead>
        <tr>
          {header.map((headerCell, i) => (
            <th key={i}>{headerCell}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((tableRow, i) => (
          <tr key={i}>
            {tableRow.map((tableCell, j) => (
              <td key={j}>{tableCell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Page = (props) => {
  const {
    pageContext: { contentSection, cta, year, title, metaDescription },
  } = props;

  return (
    <Context.Consumer>
      {({ companyName }) => (
        <Layout year={year}>
          <Helmet title={`${title} | ${companyName}`}>
            <meta name="description" content={metaDescription} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Helmet>
          <h1>{title}</h1>
          {contentSection &&
            contentSection.map((section, i) => (
              <div key={i}>
                {section.text &&
                  section.text.json &&
                  documentToReactComponents(section.text.json, options)}
                {section.images &&
                  section.images.map((image, j) => (
                    <img
                      className={css(styles.image)}
                      src={image.file.url}
                      alt={image.title}
                      key={j}
                    />
                  ))}
                {section.table &&
                  section.table.tableData &&
                  renderTable(section.table.tableData)}
                {section.richTextTable && section.richTextTable.rows && (
                  <table>
                    <tbody>
                      {section.richTextTable.rows.map(
                        (row, j) =>
                          row.cells && (
                            <tr key={j}>
                              {row.cells.map((cell, k) => (
                                <td key={k}>
                                  {documentToReactComponents(
                                    cell.content.json,
                                    options
                                  )}
                                </td>
                              ))}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            ))}
          {cta && <Cta data={cta} />}
        </Layout>
      )}
    </Context.Consumer>
  );
};

export default Page;

const styles = StyleSheet.create({
  imageRow: {
    display: 'flex',
    justifyContent: 'start',
    padding: '20px 0',
  },
  image: {
    marginLeft: 0,
    marginRight: 20,
  },
  table: {
    [`@media ${widths.desktop}`]: {
      width: '50%',
    },
  },
});
