import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from './Button';
import { colors } from '../utils/constants';

const Cta = ({ data: { button, content } }) => (
  <div className={css(styles.wrapper)}>
    <div className={css(styles.content)}>
      {documentToReactComponents(content.json)}
    </div>
    <div className={css(styles.buttonWrapper)}>
      <Button button={button} />
    </div>
  </div>
);

export default Cta;

const styles = StyleSheet.create({
  wrapper: {
    background: colors.lightGray,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    marginLeft: 40,
  },
});
