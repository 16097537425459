import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

const Button = ({ button, primaryColor, secondaryColor }) => {
  const colors = button.colorScheme
    ? {
        color: button.colorScheme.textColor,
        border: `2px solid ${button.colorScheme.textColor}`,
        backgroundColor: button.colorScheme.backgroundColor,
      }
    : null;

  return button.typeform ? (
    <a
      href={button.typeform}
      className={`${css(styles.button)} typeform-share button typeform-btn`}
      data-mode="drawer_right"
      target="_blank"
      style={colors}
    >
      {button.text}
    </a>
  ) : (
    <a href={button.slug} className={css(styles.button)} style={colors}>
      {button.text}
    </a>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    fontWeight: 900,
    letterSpacing: 0.5,
    whiteSpace: 'nowrap',
    padding: '18px 45px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderRadius: 0,
    WebkitAppearance: 'none',
    textDecoration: 'none',
    display: 'inline-block',
  },
});
